import i18n from "i18next";


export const IVA = 0.22

export const urlCatasto= "http://catastoenergetico.org"

export const urlCatastoHome = "http://catastoenergetico.org/topmenu/MyHome.aspx"
export const urlAddEdificio = urlCatasto+"/edit/gestioneedificio.aspx?Skeda=NUOVA&IDUNI=mve0ht2usgo1exm3sjdmwkau8997&MDId=4101"
export const refBase64IdCommunity = "community-5MiaiwdAPUHOtzTqcE5S"
export const refBase64IdPlant = "plant-we8fs88fd8djs"

export const THEMES = {
  LIGHT: 'LIGHT',
};
export const DEFAULT_WEBSITE_ONBOARDING = "http://catastoenergetico.org"
export const LINK_PRIVACY = "https://www.iubenda.com/privacy-policy/87466855"
export const LINK_COOKIE_POLICY = "https://www.iubenda.com/privacy-policy/87466855/cookie-policy"

export const sectionsAppLogged = [
  {
    title: "",
    items: [
      {
        title: i18n.t("my_home"),
        path: '/',
      },
      {
        title: i18n.t("gestioni_sottoscrizioni"),
        path: '/mybuildings',
      },
      {
        title: i18n.t("comunita_energetiche"),
        path: '/mycommunities',
      }
    ]
  },
];

export const sectionsAppUnLogged = [
  {
    title: "",
    items: [

    ]
  }
];
export const sectionsAppLoggedManutentore = [
  {
    title: "",
    items: [
      {
        title: i18n.t("my_home"),
        path: '/',
      },
      {
        title: i18n.t("gestioni_sottoscrizioni"),
        path: '/mybuildings',
      },
    ]
  }
];

export const sectionsAppLoggedBackOffice = [
  {
    title: "",
    items: [
      {
        title: i18n.t("my_home"),
        path: '/',
      },
      {
        title: i18n.t("gestione_comunita"),
        path: '/bo/communities',
      },
      {
        title: i18n.t("pagamenti"),
        path: '/bo/buildings',
      },
    ]
  }
];

/**CODICE COUPON **/
export const codiceDUFERCO = "00100"

/*
BACKUP
 {
        title: i18n.t("my_home"),
        path: '/',
      },
 */
