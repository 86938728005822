import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

const resources = {
  en: {
    translation: {
        "appName":"Catasto Energetico",
        "termini_condizioni": "Termini e condizioni",
        "conferma": "Conferma",
        "salva": "Salva",
        "faq": "F.A.Q",
        "subTitleFaq": "Qui puoi trovare le risposte alle domande più frequenti.",
        "torna_indietro": "Torna indietro",
        "401_subtitle": "Stai provando ad accedere ad una pagina che richiede l'autenticazione, prosegui la navigazione usando la barra in alto o torna indietro.",
        "401_subtitle_admin": "Non hai i permessi sufficienti per visualizzare questa pagina.",
        "401_title": "Errore 401 non sei autorizzato",
        "erroreServer": "Errore server",
        "500_title": "Errore 500 problema interno del server",
        "500_subtitle": "La richiesta che hai inviato, non è andata a buon fine, riprova o contatta l'assistenza del servizio tecnico, descrivendo il problema.",
        "non_trovato": "Non trovato",
        "404_title": "Errore 404 risorsa cercata non trovata",
        "404_subtitle": "Il percorso ricerca non è stato trovato, controlla di aver digitato correttamente il link, riprova.",
        "logout": "Logout",
        "vai": "Vai",
        "overview":"Home",
        "disclaimer":"Disclaimer",
        "required_iban":"Devi compilare il campo con l'iban",
        "required_invoiceidentifier":"Devi compilare il campo relativo al codice destinario/pec per l'invio della fattura",
        "profilo": "Profilo",
        "iban":"Iban",
        "cellulare":"Cellulare",
        "name":"Nome",
        "cognome":"Cognome",
        "data_nascita":"Data di nascita",
        "prosegui":"Prosegui",
        "onboarding_success":"On boarding completato con scucesso",
        "required_datanascita":"Il campo data di nascita è richiesta.",
        "dati_catasto_energetico":"Dati edificio",
        "dati_catasto_energetico_informazioni_base":"Informazioni base",
        "informazioni_aggiuntive":"Informazioni aggiuntive",
        "required_valid_iban":"E' richiesto un iban valido",
        "role_is_wrong":"Il ruolo dell'utente è sbagliato.",
        "errore_500":"Errore 500",
        "no_data_provided":"Non sono state fornite le informazioni necessarie.",
        "lista_degli_edifici":"Lista degli edifici",
        "code_not_valid":"Codice non valido",
        "verifica_dati":"Verifica dati",
        "secret_code":"Secret code",
        "username":"Username",
        "codice_generato_inviato_email":"Codice generato ed inviato via email",
        "inserimento_codice":"Inserimento codice di verifica",
        "website":"Website",
        "password":"Password",
        "my_home":"My Home",
        "home":"Home",
        "le_tue_comunita":"Le tue comunità",
        "crea_nuova_comunita":"Crea nuova comunità",
        "accedi":"Accedi",
        "password_dimenticata":"Password dimenticata",
        'subtitleLogin': "Utilizza le tue credenziali per accedere alla piattaforma",
        "edifici_amministrati":"Edifici amministrati",
        "gestioni_sottoscrizioni":"Gestioni sottoscrizioni",
        "righe_per_pagine": "Righe per pagina",
        "codice_fiscale_indirizzo_search":"Inserisci il codice fiscale o indirizzo",
        "filtri_di_ricerca":"Filtri di ricerca",
        "ordina_per":"Ordina per",
        "edifici_che_amministri":"EDIFICI CHE AMMINISTRI",
        "in_regola":"In regola",
        "non_in_regola":"Non in regola",
        "onboarding":"Onboarding",
        "province":"Province",
        "city":"City",
        "numberApartaments":"Numero appartamenti",
        "sezioneUrbana":"Sezione urbana",
        "foglio":"Foglio",
        "cap":"Cap",
        "address":"Indirizzo",
        "addressNumber":"Numero",
        "region":"Regione",
        "particella":"Particella",
        "required_username":"Lo username è obbligatorio",
        "required_password":"La password è obbligatoria",
        "error_in_creating":"Errore in creazione",
        "incompleto":"Incompleto",
        "attiva_abbonamento":"Attiva abbonamento",
        "sospendi_abbonamento":"Sospendi abbonamento",
        "abbonamento_non_attivato":"Abbonamento non attivato, riprova nei prossimi giorni o contatta l'assistenza tecnica: backoffice@catastoenergetico.org",
        "abbonamento_non_sospeso":"Abbonamento non sospeso, riprova nei prossimi giorni o contatta l'assistenza tecnica: backoffice@catastoenergetico.org",
        "abbonamento_attivato":"Abbonamento attivato con successo!",
        "abbonamento_sospeso_success":"Abbonamento sospeso con successo!",
        "ri_attiva_abbonamento":"Riattiva l'abbonamento",
        "loading":"Caricamento in corso ...",
        "aggiungi_edificio":"Aggiungi edificio",
        "annulla":"Annulla",
        "continua":"Continua",
        "scegli_gli_edifici":"Scegli gli edifici",
        "communityName":"Nome comunità",
        "scegli_i_tuoi_edifici":"Scegli i tuoi edifici",
        "esamina_i_risultati":"Esamina i risultati",
        "coinvolgi_condomini":"Coinvolgi i condòmini",
        "inizia_analisi_preliminare":"Delibera e carica documenti",
        "coinvolgi_condomini_inizia_analisi":"Coinvolgi i condòmini",
        "compila_i_dati":"Compila i dati",
        "delibera_assemblea_carica_doc":"Delibera e carica i documenti",
        "procedi_costituzione":"Procedi con la costituzione",
        "dai_un_nome_comunita":"Dai un nome alla tua comunità",
        "required_secret_code":"Il codice segreto è necessario",
        "edifici_di_cui_sei_terzo_responsabile":"Edifici di cui sei Terzo Responsabile",
        "stato_abbonamenti":"Stato abbonamenti",
        "edifici_possibile_sottoscrizione":"EDIFICI PER I QUALI È POSSIBILE EFFETTUARE LA SOTTOSCRIZIONE",
        "edifici_gia_sottoscritti":"EDIFICI GIÀ SOTTOSCRITTI",
        "completa_pagamento":"Completa pagamento",
        "non_ci_sono_abbonamenti_attivi":"Non ci sono abbonamenti attivi",
        "edifici_associati_impianti_terzo_respos":"EDIFICI ASSOCIATI AGLI IMPIANTI DI CUI SEI TERZO RESPONSABILE",
        "seleziona_x_il_pagamento":"Seleziona per il pagamento",
        "le_mie_comunita":"Le mie comunità",
        "tutti_gli_edifici_abbonati":"Tutti gli edifici sono abbonati",
        "provincia":"Provincia",
        "regione":"Regione",
        "edifici_selezionati":"Edifici selezionati",
        "nessun_edificio_selezionato":"Nessun edificio selezionato",
        "torna_a_selezione_edifici":"Torna a selezione edifici",
        "avanti":"Avanti",
        "error_creation_community":"Errore nella creazione della comunità",
        "community_creata":"Comunità creata con successo.",
        "required_name_community":"Il campo nome community è obbligatorio",
        "all":"Tutte",
        "all_m":"Tutti",
        "luci":"Luci",
        "centrale_termica":"Centrale termica",
        "ascensore":"Ascensore",
        "altro":"Altro",
        "si":"Si",
        "no":"No",
        "vecchia_generazione":"É di vecchia generazione",
        "basso_consumo":"É a basso consumo",
        "in_parte_cond_privt":"In parte condominiale in parte privata",
        "riscaldamento_centralizzato":"Riscaldamento centralizzato",
        "riscaldamento_centralizzato_ed_acqaua":"Riscaldamento e acqua calda sanitaria centralizzati",
        "quale_anno_generatore_termico":"Quale è l'anno del generatore termico?",
        "i_tuoi_edifici":"I TUOI EDIFICI",
        "condominiale":"Condominiale",
        "privata":"Privata",
        "salvataggio_non_riuscito":"Salvataggio non riuscito",
        "informazioni_mancanti":"Informazioni mancanti",
        "avvia_analisi":"Avvia analisi",
        "data_di_scadenza":"Data di scadenza",
        "error_obtaining_communities_list":"Errore nell'ottenimento della lista delle community",
        "ricerca_x_nome":"Ricerca per nome",
        "error_creating_survey":"Errore nella creazione del sondaggio",
        "indietro":"Indietro",
        "in_attesa_delle_risposte_condomini":"In attesa delle risposte dei condomini...",
        "link_raccolta_dati":"Link raccolta dati",
        "termina_ora":"Termina ora",
        "risultati":"Risultati",
        "risposte_totali_ottenute":"Risposte totali ottenute",
        "edifici_coinvolti":"Edifici coinvolti",
        "risposte":"Risposte",
        "surveyStartTitle":"Compila il sondaggio",
        "surveyStopTitle":"Il sondaggio è terminato",
        "not_found":"Non trovato",
        "community_non_trovata":"Community non trovata.",
        "edificio_gia_in_altre_comunita": "Edificio già compreso in altra comunità",
        "edificio_gia_in_altre_comunita_ipotesi":"Edificio già compreso in altra ipotesi di comunità",
        "distanza_eccessiva":"Distanza eccessiva",
        "completa_il_sondaggio":"Completa il sondaggio",
        "edificio_selezionato":"Edificio selezionato",
        "other":"Altro",
        "orario":"Orario",
        "spezzato":"Spezzato",
        "ora_inizio":"Ora inizio",
        "ora_fine":"Ora fine",
        "aperto24h":"Aperto 24h",
        "chiuso":"Chiuso",
        "pagamenti":"Pagamenti",
        "gestione_comunita":"Gestione comunità",
        "comunita_in_sviluppo":"Comunità in sviluppo",
        "inserisci_nome_comunita":"Inserisci nome comunità",
        "ultima_modifica":"Ultima modifica",
        "stato":"Stato",
        "sconosciuto":"Sconosciuto",
        "attendi_sondaggio":"Attendi sondaggio",
        "inizia_analisi":"Inizia analisi",
        "scegli_edifici":"Scegli edifici",
        "delibera_assemblea":"Delibera in assemblea",
        "edifici_catasto_energetico":"Gli edifici di catasto energetico",
        "comunita_energo":"Tutte le comunità",
        "in_sviluppo":"In sviluppo",
        "la_data_non_puo_essere_antecedente":"La data non può essere antecedente",
        "salvataggio_riuscito":"Salvataggio riuscito",
        "esamina_risultati_e_procedi":"Esamina i risultati e procedi",
        "riepilogo":"Riepilogo",
        "comunita_energetica":"Comunità energetica",
        "impianti_fotovoltaici":"Impianti fotovoltaici",
        "compensi_sviluppatore":"Compensi sviluppatore",
        "dettaglio":"Dettaglio",
        "aggiungi_edifici":"Aggiungi edifici",
        "successivo":"Successivo",
        "problemi_onboarding_owner":"Problemi con la registrazione dell'amministratore",
        "required_valid_website":"Inserisci un indirizzo web valido",
        "no_community":"Non ci sono comunità",
        "title_onboarding":"Abbiamo integrato nuove funzionalità",
        "subtitle_onboarding":"D'ora in avanti potrai gestire i pagamenti direttamente in app e accedere a nuove funzionalità come lo sviluppo di comunità energetiche. Sono però necessari alcune informazioni aggiuntive",
        "per_modificare_torna_catasto":"Per modificare questo campo torna a Modifica dati personali dalla home",
        "problemi_nel_caricamento":"Problemi nel caricamento",
        "ditta":"Ditta",
        "nominativo":"Nominativo",
        "gestione_sottoscrizioni":"Abbonamento edifici",
        "dati_edificio":"Dati edificio",
        "verifica_lo_stato_degli_abbonamenti_a_catasto":"Verifica lo stato degli abbonamenti a Catasto Energetico",
        "verifica_stato_edifici_amministrati_contractor":"Verifica lo stato degli abbonamenti a Catasto Energetico di ciascun edificio di cui sei Terzo responsabile/Manutentore",
        "subtitle_onboarding_building":"Rivedi le informazioni associate all'edificio ed inserisci l'IBAN del condominio sul quale verrà addebitato l'abbonamento a Catasto Energetico",
        "inserisci_iban_condominio":"Inserisci iban condominio",
        "sottoscrizione_effettuata_con_successo":"Sottoscrizione effettuata con successo",
        "sviluppo_gestione_comunita":"Sviluppo gestione comunità",
        "iban_condominio":"Iban condominio",
        "modifica_iban":"Modifica iban",
        "message_cc_disabled":"Verifica di aver inserito una carta di credito valida.",
        "metodo_di_pagamento_aggiornato":"Metodo di pagamento aggiornato.",
        "i_tuoi_dati_di_pagamento":"Dati di pagamento",
        "dati":"Dati",
        "subtitle_i_tuoi_dati_di_pagamento":"In questa sezione puoi inserire il tuo IBAN personale. Sarà utilizzato solo per accrediti futuri nei tuoi confronti",
        "subtitle_i_tuoi_dati_di_pagamento_manutentore":"In questa sezione è possibile modificare i tuoi dati di pagamento",
        "dati_di_pagamento":"Dati di pagamento",
        "modifica_dati_carta_di_credito":"Modifica dati carta di credito",
        "last4":"Ultimi 4 numeri della carta",
        "le_comunita_in_sviluppo":"Le comunità in sviluppo",
        "da_inserire_dati_coperture":"Da inserire dati coperture",
        "gestisci":"Gestisci",
        "le_comunita_in_gestione":"Le comunità in gestione",
        "in_gestione":"In gestione",
        "compila_dati":"Compila dati",
        "inserire_dati_coperture":"Inserire dati coperture",
        "link_sondaggio":"Link sondaggio",
        "scandeza_link_sondaggio":"Scandeza link sondaggio",
        "data_creazione":"Data di creazione",
        "email":"Email",
        "amministratore":"Amministratore",
        "telefono":"Telefono",
        "torna_a_gestione_comunita":"Torna a gestione comunità",
        "edifici_inseriti":"Edifici inseriti",
        "note":"Note",
        "aree_individuate":"Aree individuate",
        "aggiungi_area":"Aggiungi area",
        "area":"Area",
        "lunghezza":"Lunghezza",
        "larghezza":"Larghezza",
        "azimut":"Azimut",
        "benvenuto":"Benvenuto",
        "comunita_energetiche":"Comunità energetiche",
        "delibera_in_assemblea_carica_doc":"Delibera in assemblea e carica i documenti richiesti",
        "condomini_coperture_interessate":"Condomini le cui coperture sono interessate",
        "nuovi_impianti":"Nuovi impianti",
        "numero_impianti":"Numero impianti",
        "investimento_stimato":"Investimento stimato",
        "scarica_materiale_informativo_assemblea":"Scarica materiale informativo da portare in assemblea",
        "scarica_incarico_progettazione_esecutiva":"Scarica incarico progettazione esecutiva",
        "carica_incarico_progettazione_esecutiva_firmato":"Carica incarico progettazione esecutiva firmato",
        "carica_delibera_incarico":"Carica delibera incarico",
        "scegli_file":"Scegli file",
        "procedi_con_la_costituzione":"Procedi con la costituzione",
        "documenti_da_scaricare":"Documenti da scaricare",
        "carica_i_seguenti_documenti_firmati":"Carica i seguenti documenti firmati",
        "carica_documenti":"Carica documenti",
        "carica_statuto_firmato":"Carica statuto firmato",
        "scarica_statuto_comunita":"Scarica Statuto comunità",
        "residenziale":"Residenziale",
        "ufficio":"Ufficio",
        "altre_attivita":"Negozio/Artigiano/Ristorante",
        "dati_identificativi":"Dati identificativi",
        "saving_survey_ok":"Sondaggio inviato con successo",
        "error_saving_survey":"Errore nel salvataggio del sondaggio",
        "sondaggio_non_trovato":"Sondaggio non trovato o scaduto",
        "sondaggio_completato":"Hai finito! Grazie per aver compilato il sondaggio.",
        "amministratore_manutentore_nome":"Nome dell'amminstratore o manutentore",
        "not_available":"Non disponibile",
        "risultati_non_ancora_disponibili":"Risultati non ancora disponibili",
        "non_ci_sono_edifici":"Non ci sono edifici",
        "seleziona_tutti":"Seleziona tutti",
        "edifici_considerati":"Edifici considerati",
        "importo_iva_22inclusa":"Importo (iva 22% inclusa):",
        "edificio": "Edificio",
        "riepilogo_edifici_ddot":"Riepilogo edifici:",
        "riepilogo_edifici":"Riepilogo edifici",
        "pagamento_e_durata":"Pagamento e durata",
        "carta_addebito_ddot":"Carta di addebito:",
        "durata_12_mesi_ddot":"Durata 12 mesi:",
        "iban_addebito_ddot":"IBAN addebito: ",
        "periodo_addebito_12_mesi":"Periodo di addebito 12 mesi:",
        "validita_12_mesi":"Validità 12 mesi:",
        "modifica_coperture":"Modifica coperture",
        "elimina":"Elimina",
        "rigenera_risultati":"Rigenera risultati",
        "e_stata_avviata_la_generazione_dei_risultati":"E' stata avviata con successo la rigenerazione dei risultati",
        "problemi_avvio_rigenerazione":"Problemi nella richiesta di rigenerazione dei risultati",
        "error_cancel_community":"Errore nella cancellazione della comunità",
        "community_cancellata_con_successo":"Community cancellata con successo",
        "sei_sicuro_di_voler_cancellare_la_comunita":"Sei sicuro di voler cancella la comunità: ",
        "sei_sicuro_di_voler_sospendere_abbonamento_edificio":"Sei sicuro di voler sospendere l'abbonamento per l'edificio:",
        "community_aggiornata":"Comunità aggiornata con successo",
        "error_update_community":"Errore aggiornamento comunità",
        "scarica_json_risultati":"Scarica json risultati",
        "nome":"Nome",
        "stato_attuale":"Stato attuale",
        "privacy":"Privacy",
        "coperture":"Coperture",
        "sondaggio":"Sondaggio",
        "turno_unico":"Turno unico",
        "onboarding_problem_creation_user":"Problemi nella creazione dell'utente, contatta il supporto",
        "payment_method_impostato":"Metodo di pagamento non impostato, non puoi procedere",
        "inserisci_la_carta_di_credito":"Inserisci la carta di credito",
        "pagamento_non_riuscito_contatta_assistenza":"Pagamento non riuscito: riprova nei prossimi giorni o contatta l'assistenza tecnica: backoffice@catastoenergetico.org",
        "error_aggiornamento_metodo_di_pagamento":"Problemi nell'aggiornamento del pagamento, riprova o contatta l'assistenza tecnica: backoffice@catastoenergetico.org",
        "inserisci_identificativo_fatture":"Inserisci il codice identificativo per l'invio delle fatture(SDI o pec)",
        "identificativo_fatture":"Identificativo delle fatture(SDI o PEC)",
        "modifica_dati":"Modifica dati",
        "inserisci_dati_cc_contractor":"Inserisci i dati della carta di credito/debito con cui potrai effettuare pagamenti per gli edifici",
        "dati_profilo":"Profilo",
        "i_tuoi_dati_profilo":"I tuoi dati del profilo",
        "modifica":"Modifica",
        "error_updating_profile":"Errore nell'aggiornamento del profilo",
        "problemi_metodo_di_pagamento_edificio":"Il tuo edificio risulta già registrato, ma non riusciamo a recuperare il metodo di pagamento precedentemente impostato, contatta l'assistenza tecnica: backoffice@catastoenergetico.org",
        "inserimento_dati_coperture":"Inserimento dati coperture",
        "non_risultano_edifici_coperture":"Non risultano edifici che necessitano di inserimento delle coperture, verifica che siano stati compilati i dati",
        "in_attesa_di_conferma":"In attesa di conferma",
        "offerta_da":"Offerto da:",
        "indirizzo_edificio":"Indirizzo edificio:",
        "emailSoggettoTerzo":"Email del soggetto terzo",
        "nomeSoggettoTerzo":"Nome del soggetto terzo",
        "dati_importazione_massiva":"Dati per importazione massiva edifici/amministratori",
        "dati_importazione_massiva_subtitle":"In questa sezione potrai caricare un file csv che importerà in automatico gli edifici e gli amministratori, associandoli se necessario",
        "requiredNomeSoggettoTerzo":"Il nome del soggetto terzo è obbligatorio",
        "requiredEmailSoggettoTerzo":"La mail del soggetto terzo è obbligatoria",
        "required_csv":"Il csv è obbligatorio, verifica di averlo caricato correttamente o riprova",
        "importazione_completata":"Importazione completata.",
        "tipo_di_importazione":"Tipo di importazione",
        "simple":"Semplificata",
        "terzo":"Terzo",
        "scarica_qrcode":"Scarica Qr-Code",
        "copyright_footer":"© 2011-2024 Catasto Energetico. All Rights Reserved.",
        "vedi_risposte":"Vedi chi ha risposto",
        "definisci_configurazione":"Definisci configurazione",
        "procedi_configurazione":"Procedi configurazione",
        "configurazione":"Configurazione",
        "scegli_edifici_compresi_configuraz":"Scegli edifici compresi nella configurazione",
        "scegli_la_configurazione":"Scegli la configurazione",
        "scegli_configurazione_intro_pt1":"A valle della decisione delle assemblee, ",
        "scegli_configurazione_intro_pt2":" definitiva. Gli ",
        "edifici_preselezionati":"edifici preselezionati",
        "scegli_configurazione_intro_pt3":" sono quelli consigliati da Energo AI.",
        "configurazione_selezionata":"Configurazione selezionata",
        "avendo_selezionato_piu_edifici_conf_disp":"Avendo selezionato più di un edificio l'unica configurazione possibile è:",
        "btn_conf_cer":"COMUNITA\nENERGETICA\nRINNOVABILE (CER)",
        "btn_conf_auc":"GRUPPO DI\nAUTOCONSUMATORI\n(AUC)",
        "avendo_selezionato_pt1":"Avendo selezionato un solo edificio puoi decidere se procedere con le pratiche per un ",
        "avendo_selezionato_pt2":"o una",
        "vantaggi":"VANTAGGI",
        "svantaggi":"SVANTAGGI",
        "delibera_assemblea_e_carica_documenti_richiesta":"Delibera in assemblea e carica i documenti richiesti",
        "soggetto_giuridico_pt1":"Come primo passo è necessario procedere con la costituzione del nuovo soggetto giuridico.",
        "scarica_i_template":" Scarica i template,",
        "soggetto_giuridico_pt2":"costituisci la comunità o l'autoconsumatore collettivo.(fai richiesta di codice fiscale presso l'Agenzia delle Entrate nel caso di Comunità energetica) e ",
        "soggetto_giuridico_pt3":"carica i documenti indicati qui sotto firmati.",
        "da_completare":"Da completare",
        "soggetto_giuridico":"Soggetto giuridico",
        "documenti_per_la_costituzione_di_un_nuovo_soggetto_giuridico":"Documenti per la costituzione del nuovo soggetto giuridico",
        "impianto_pt1":"Per tutti i nuovi impianti fotovoltaici è necessario raccogliere alcuni",
        "documenti":" documenti ",
        "impianto_pt2":"che vengono prodotti dal ",
        "progettista":"Progettista",
        "impianto_pt3":" e dalla ",
        "ditta_installatrice":"installatrice.",
        "impianto_pt4":" Inserisci nel seguito nome e recapito email del professionista e dell'installatore al fine di ricordargli di caricare i documenti. Catasto Energetico invierà per te la richiesta di tutti i documenti necessari. Nel pannello a destra puoi monitorare l'andamento del caricamento dei dati.",
        "membri_pt1":"Infine condividi il seguente link a tutti i ",
        "tutti":"tutti",
        "membri":"membri",
        "membri_pt2":" della configurazione per raccogliere i dati necessari da ogni membro. Nella casella a destra puoi monitorare l'andamento della raccolta.",
        "membri_della_configurazione":"Membri della configurazione",
        "documenti_dell_impianto":"Documenti dell'impianto di ",
        "incentivi_pt1":"Non appena tutti i ",
        "incentivi_pt2": " saranno caricati, Catasto Energetico inoltrerà la richiesta al GSE per l'ottenimento degli",
        "incentivi":" incentivi",
        "scarica_i_template_compilali_e_ricaricali":"Scarica i template,\ncompilali e ricaricali",
        "scarica":"Scarica",
        "regolamento":"Regolamento",
        "statuto":"Statuto",
        "atto_costitutivo":"Atto costitutivo",
        "procura":"Procura",
        "carica_file":"Carica file",
        "file_supportati":"File supportati",
        "atto_costitutivo_delibera":"Atto costitutivo / delibera",
        "dati_indicati_pt1":"Dopo aver costituito la comunità, ",
        "inserisci_i_dati_indicati":"inserisci i dati indicati ",
        "dati_indicati_pt2":"qui sotto in modo da permetterci di presentare la richiesta di accesso al servizio di incentivazione dell'energia.",
        "intestatario_cc": "Intestatario c/c",
        "codice_fiscale":"Codice fiscale",
        "nome_soggetto_giuridico":"Nome soggetto giuridico",
        "invia_richiesta":"Invia richiesta",
        "invia_richiesta_pt1":" al progettista e all'installatore di caricare i documenti necessari per le prossime fasi.",
        "documenti_caricati":"Documenti caricati",
        "progettazione_esecutiva":"Progettazione esecutiva",
        "nome_progettista":"Nome progettista",
        "indirizzo_email":"Indirizzo e-mail",
        "installazione_impianto":"Installazione impianto",
        "nome_azienda":"Nome azienda",
        "schema_elettrico_unifilare":"Schema elettrico unifilare",
        "verbale_attivazione_contatore":"Verbale di attivazione del contatore",
        "regolamento_di_esercizio":"Regolamento di esercizio",
        "attestato_esercizio_gaudi":"Attestato di esercizio Gaudi",
        "checklist_dnsh":"Checklist DNSH",
        "dati_economici_impianto":"Dati economici impianto",
        "foto_etichette_impianto_inverter":"Foto delle etichette dell'impianto, inverter accumulo",
        "numero_matricola_pannelli":"Numero di matricola dei pannelli installati",
        "l_impianto_di":"L'impianto di",
        "sara_realizzato_dalle_stesse_aziende":"sarà realizzato dalle stesse aziende che realizzeranno l'impianto",
        "produttore_pt1":"Il ",
        "produttore":"Produttore",
        "produttore_pt2":" è l'intestatario dell'officina elettrica di produzione dell'energia ovvero il firmatario del regolamento di esercizio dell'impianto. ",
        "nel_caso_in_cui_produttore_non_coincida_cer_auc":"Nel caso in cui il produttore non coincida con il referente della CER o dell'AUC",
        "produttore_pt3":",è necessario compilare e firmare un mandato e caricarlo corredato di documento di identità. Nel caso in cui il produttore degli impianti coincida con il referente, non è necessario fornire mandato.",
        "documenti_dei_produttore_impianti":"Documenti dei produttori impianti",
        "documento_identita":"Documenti d'identità",
        "mandato_firmato":"Mandato firmato",
        "produttori":"Produttori",
        "clicca_sul_link":"Clicca sul link",
        "membri_conf_link_pt1":" sottostante o",
        "inquadra_qr_code":" inquadra il Qr-Code ",
        "membri_conf_link_pt2":" per compilare i dati riguardanti i POD condominiali. ",
        "invia":"Invia",
        "membri_conf_link_pt3":" poi lo stesso link o il Qr-Code ai membri della configurazione, con un copia e incolla o scaricando e inviando il QR code, per permettere il caricamento dei dati.",
        "risposte_ottenute":"Risposte ottenute",
        "dati_catastali":"Dati catastali",
        "conferma_dati_catastali":"Conferma i dati catastali dell'immobile in cui è stato realizzato l'impianto",
        "subAlterno":"Subalterno",
        "solo_se_presente":"* solo se presente",
        "dati_posizionali":"Dati posizionali",
        "conferma_i_dati_posizionali":"Conferma i dati dell'immobile in cui è stato realizzato l'impianto",
        "inclinazione_rispetto_azimut":"Inclinazione rispetto all'azimut:",
        "esposizione_prevalente":"Esposizione prevalente:",
        "longitudine_wgs84":"Longitudine (WGS84 decimali):",
        "latitudine_wgs84":"Latitudine (WGS84 decimali):",
        "conferma_dati_posizionali":"Conferma i dati dell'immobile in cui è stato realizzato l'impianto",
        "impianto_dotato_di_inseguimento_solare":"L'impianto è dotato di dispositivo di inseguimento solare?",
        "impianto_costituito_pannelli_bifacciali":"L'impianto è costituito da pannelli bifacciali?",
        "carica_documenti_dell_impianto_di":"Carica i documenti relativi all'impianto di",
        "file_caricato":"File caricato",
        "moduli_fotovoltaici":"Moduli fotovoltaici",
        "inverter":"Inverter",
        "tecnologia":"Tecnologia",
        "marca":"Marca",
        "modello":"Modello",
        "potenza":"Potenza",
        "numero_moduli":"Numero moduli",
        "matricola":"Matricola",
        "foto_etichetta_ogni_modulo_fotovoltaico":"FOTO DELL'ETICHETTA DI OGNI MODULO FOTOVOLTAICO",
        "foto_targhetta_ogni_inverter":"FOTO DELLA TARGHETTA DI OGNI INVERTER",
        "numero_matricola_pannelli_installati":"NUMERO DI MATRICOLA DEI PANNELLI INSTALLATI",
        "dati_giuridici":"Dati giuridici",
        "persona_fisica":"Persona fisica",
        "imprenditore_ditta_individuale_impresa_agricola":"Imprenditore, ditta individuale, impresa agricola",
        "persona_giuridica":"Persona giuridica",
        "condominio":"Condominio",
        "piva":"P.IVA",
        "rapp_legale":"Rappresentante legale",
        "procuratore":"Procuratore",
        "firmatario":"Firmatario",
        "ragioneSociale":"Ragione Sociale",
        "pod":"POD",
        "inserisci_codice_pod_pt1":"Inserisci il codice ",
        "inserisci_codice_pod_pt2":" lo trovi in\n bolletta(prima pagina)",
        "scarica_mandato":"Scarica mandato",
        "mandato":"Mandato",
        "impianti":"Impianti",
        "elimina_file":"Elimina file",
        "carica_documento":"Carica documento",
        "policristallino":"Policristallino",
        "monocristallino":"Monocristallino",
        "filmSottile":"Film sottile",
        "amorfo":"Amorfo",
        "informazioni_inserite":"Informazioni inserite con successo",
        "error_saving_member":"Errore nel salvataggio dei dati del membro della comunità",
        "file_rimosso_con_successo":"File rimosso con successo.",
        "error_rimozione_successo":"Errore nella rimozione del file.",
        "caricamento_completato":"Caricamento completato",
        "errore_caricamento_files":"Errore caricamento files",
        "errore_creazione_configurazione":"Errore nella creazione della confiurazione della comunità",
        "configurazione_creata_con_successo":"Configurazione creata con successo",
        "documentazione_aggiornata":"Documentazione aggiornata",
        "documentazione_non_aggiornata":"Documentazione non aggiornata",
        "nessun_impianto_selezionato":"Non hai selezionato nessun impianto, non puoi procedere con la configurazione.",
        "invia_richieste":"Invia richieste",
        "richieste_inviata_con_successo":"Richieste inviate con successo",
        "errore_invio_richieste":"Problemi nell'invio della richieste.",
        "delibera_in_assemblea":"Delibera in assemblea",
        "errore_elaborazione":"Errore di elaborazione",
        "apri":"Apri",
        "errore_download":"Problemi nel download del file.",
        "recapiti":"Recapiti",
        "completato":"Completato",
        "copia_link":"Copia link",
        "scarica_compilazioni_membri":"Scarica JSON compilazioni membri",
        "richiesta_inviata_con_successo":"Richiesta inviata con successo",
        "errore_invio_richiesta":"Problemi nell'invio della richiesta.",
        "riapri_sondaggio":"Riapri sondaggio",
        "quale_numero_appartamenti_interni":"Qual è il numero indicativo di interni del condominio (abitazioni, uffici, attività…)?",
        "partecipazione_parziale":"Partecipazione parziale",
        "partecipazione_totale":"Partecipazione totale",
        "dettaglio_investimento":"Dettaglio investimento",
        "dettaglio_per_edificio":"Dettaglio per edificio",
        "consumatore":"Consumatore",
        "edifici":"Edifici",
        "nessun_impianto":"Nessun impianto",
        "potenza_impianto":"potenza impianto",
        "edificio_escluso_conf":"Edificio escluso dalla configurazione",
        "inserisci_i_dati":"Inserisci i dati",
        "documenti_caricati_dai_professionisti":"Documenti caricati dai professionisti",
        "torna_alla_scelta_degli_edifici":"Torna alla scelta degli edifici",
        "attenzione":"Attenzione",
        "warning_modifica_edifici_perdi_risultati":"Se modifichi la configurazione della comunità, perderai i risultati ottenuti. Tutti i dati inseriti associati agli edifici e i questionari ottenuti verranno mantenuti e utilizzati per calcolare i risultati della nuova configurazione.",
        "error_comunita_non_sviluppabile":"Non è stato possibile generare i risultati poichè in nessuno degli edifici considerati è possibile installare un impianto fotovoltaico.",
        "elaborazione_risultati":"Elaborazione dei risultati",
        "crea_comunita":"Crea comunità",
        "nome_comunita_title":"Nome Comunità",
        "link_copiato":"Link copiato con successo",
        "elaborazione_completata":"Elaborazione completata",
        "richiedi_contatto":"Richiedi contatto",
        "clicca_su":"Clicca su ",
        "richiedi_contatto_min":"richiedi contatto",
        "per_ricevere_una_nostra_telefonata_assieme_risultati":" per ricevere una nostra telefonata. Esamineremo assieme i risultati ottenuti e ti forniremo tutte le indicazioni per procedere con la costituzione della tua configurazione di autoconsumo diffuso.",
        "approva_richiesta_dopo_contatto":"Approva richiesta dopo contatto",
        "approvazione_richiesta_dopo_contatto_successo":"Approvazione andata a buon fine!",
        "errore_approvazione_richiesta_dopo_contatto":"Errore approvazione richiesta dopo contatto!",
        "autoconsumatore_collettivo":"Autoconsumatore collettivo",
        "sconto_disclaimer_building":"Usufruisci di un prezzo scontato del ",
        "rigenera_coordinate_identificativo":"Rigenera coordinate ed identificativo",
        "operazione_failed":"Operazione fallita",
        "operazione_success":"Operazione eseguita con successo",
        "rigenera_coordinate_gse_identificativo":"Rigenera per tutti gli edifici GSE identifier e coordinate",
        "gli_edifici_selezionati":"Gli edifici selezionati",
        "gli_edifici_che_amministri":"Gli edifici che amministri"
    }
  },
  it: {
    translation: {
        "appName":"Catasto Energetico",
        "termini_condizioni": "Termini e condizioni",
        "conferma": "Conferma",
        "salva": "Salva",
        "faq": "F.A.Q",
        "subTitleFaq": "Qui puoi trovare le risposte alle domande più frequenti.",
        "torna_indietro": "Torna indietro",
        "401_subtitle": "Stai provando ad accedere ad una pagina che richiede l'autenticazione, prosegui la navigazione usando la barra in alto o torna indietro.",
        "401_subtitle_admin": "Non hai i permessi sufficienti per visualizzare questa pagina.",
        "401_title": "Errore 401 non sei autorizzato",
        "erroreServer": "Errore server",
        "500_title": "Errore 500 problema interno del server",
        "500_subtitle": "La richiesta che hai inviato, non è andata a buon fine, riprova o contatta l'assistenza del servizio tecnico, descrivendo il problema.",
        "non_trovato": "Non trovato",
        "404_title": "Errore 404 risorsa cercata non trovata",
        "404_subtitle": "Il percorso ricerca non è stato trovato, controlla di aver digitato correttamente il link, riprova.",
        "logout": "Logout",
        "vai": "Vai",
        "overview":"Home",
        "disclaimer":"Disclaimer",
        "required_iban":"Devi compilare il campo con l'iban",
        "required_invoiceidentifier":"Devi compilare il campo relativo al codice destinario/pec per l'invio della fattura",
        "profilo": "Profilo",
        "iban":"Iban",
        "cellulare":"Cellulare",
        "name":"Nome",
        "cognome":"Cognome",
        "data_nascita":"Data di nascita",
        "prosegui":"Prosegui",
        "onboarding_success":"On boarding completato con scucesso",
        "required_datanascita":"Il campo data di nascita è richiesta.",
        "dati_catasto_energetico":"Dati edificio",
        "dati_catasto_energetico_informazioni_base":"Informazioni base",
        "informazioni_aggiuntive":"Informazioni aggiuntive",
        "required_valid_iban":"E' richiesto un iban valido",
        "role_is_wrong":"Il ruolo dell'utente è sbagliato.",
        "errore_500":"Errore 500",
        "no_data_provided":"Non sono state fornite le informazioni necessarie.",
        "lista_degli_edifici":"Lista degli edifici",
        "code_not_valid":"Codice non valido",
        "verifica_dati":"Verifica dati",
        "secret_code":"Secret code",
        "username":"Username",
        "codice_generato_inviato_email":"Codice generato ed inviato via email",
        "inserimento_codice":"Inserimento codice di verifica",
        "website":"Website",
        "password":"Password",
        "my_home":"My Home",
        "home":"Home",
        "le_tue_comunita":"Le tue comunità",
        "crea_nuova_comunita":"Crea nuova comunità",
        "accedi":"Accedi",
        "password_dimenticata":"Password dimenticata",
        "subtitleLogin": "Utilizza le tue credenziali per accedere alla piattaforma",
        "edifici_amministrati":"Edifici amministrati",
        "gestioni_sottoscrizioni":"Gestioni sottoscrizioni",
        "righe_per_pagine": "Righe per pagina",
        "codice_fiscale_indirizzo_search":"Inserisci il codice fiscale o indirizzo",
        "filtri_di_ricerca":"Filtri di ricerca",
        "ordina_per":"Ordina per",
        "edifici_che_amministri":"EDIFICI CHE AMMINISTRI",
        "in_regola":"In regola",
        "non_in_regola":"Non in regola",
        "onboarding":"Onboarding",
        "province":"Province",
        "city":"City",
        "numberApartaments":"Numero appartamenti",
        "sezioneUrbana":"Sezione urbana",
        "foglio":"Foglio",
        "cap":"Cap",
        "address":"Indirizzo",
        "addressNumber":"Numero",
        "region":"Regione",
        "particella":"Particella",
        "required_username":"Lo username è obbligatorio",
        "required_password":"La password è obbligatoria",
        "error_in_creating":"Errore in creazione",
        "incompleto":"Incompleto",
        "attiva_abbonamento":"Attiva abbonamento",
        "sospendi_abbonamento":"Sospendi abbonamento",
        "abbonamento_non_attivato":"Abbonamento non attivato, riprova nei prossimi giorni o contatta l'assistenza tecnica: backoffice@catastoenergetico.org",
        "abbonamento_non_sospeso":"Abbonamento non sospeso, riprova nei prossimi giorni o contatta l'assistenza tecnica: backoffice@catastoenergetico.org",
        "abbonamento_attivato":"Abbonamento attivato con successo!",
        "abbonamento_sospeso_success":"Abbonamento sospeso con successo!",
        "ri_attiva_abbonamento":"Riattiva l'abbonamento",
        "loading":"Caricamento in corso ...",
        "aggiungi_edificio":"Aggiungi edificio",
        "annulla":"Annulla",
        "continua":"Continua",
        "scegli_gli_edifici":"Scegli gli edifici",
        "communityName":"Nome comunità",
        "scegli_i_tuoi_edifici":"Scegli i tuoi edifici",
        "esamina_i_risultati":"Esamina i risultati",
        "coinvolgi_condomini":"Coinvolgi i condòmini",
        "inizia_analisi_preliminare":"e inizia l'analisi preliminare",
        "coinvolgi_condomini_inizia_analisi":"Coinvolgi i condòmini",
        "compila_i_dati":"Compila i dati",
        "delibera_assemblea_carica_doc":"Delibera e carica i documenti",
        "procedi_costituzione":"Procedi con la costituzione",
        "dai_un_nome_comunita":"Dai un nome alla tua comunità",
        "required_secret_code":"Il codice segreto è necessario",
        "edifici_di_cui_sei_terzo_responsabile":"Edifici di cui sei Terzo Responsabile",
        "stato_abbonamenti":"Stato abbonamenti",
        "edifici_possibile_sottoscrizione":"EDIFICI PER I QUALI È POSSIBILE EFFETTUARE LA SOTTOSCRIZIONE",
        "edifici_gia_sottoscritti":"EDIFICI GIÀ SOTTOSCRITTI",
        "completa_pagamento":"Completa pagamento",
        "non_ci_sono_abbonamenti_attivi":"Non ci sono abbonamenti attivi",
        "edifici_associati_impianti_terzo_respos":"EDIFICI ASSOCIATI AGLI IMPIANTI DI CUI SEI TERZO RESPONSABILE",
        "seleziona_x_il_pagamento":"Seleziona per il pagamento",
        "le_mie_comunita":"Le mie comunità",
        "tutti_gli_edifici_abbonati":"Tutti gli edifici sono abbonati",
        "provincia":"Provincia",
        "regione":"Regione",
        "edifici_selezionati":"Edifici selezionati",
        "nessun_edificio_selezionato":"Nessun edificio selezionato",
        "torna_a_selezione_edifici":"Torna a selezione edifici",
        "avanti":"Avanti",
        "error_creation_community":"Errore nella creazione della comunità",
        "community_creata":"Comunità creata con successo.",
        "required_name_community":"Il campo nome community è obbligatorio",
        "all":"Tutte",
        "all_m":"Tutti",
        "luci":"Luci",
        "centrale_termica":"Centrale termica",
        "ascensore":"Ascensore",
        "altro":"Altro",
        "si":"Si",
        "no":"No",
        "vecchia_generazione":"É di vecchia generazione",
        "basso_consumo":"É a basso consumo",
        "in_parte_cond_privt":"In parte condominiale in parte privata",
        "riscaldamento_centralizzato":"Riscaldamento centralizzato",
        "riscaldamento_centralizzato_ed_acqaua":"Riscaldamento e acqua calda sanitaria centralizzati",
        "quale_anno_generatore_termico":"Quale è l'anno del generatore termico?",
        "i_tuoi_edifici":"I TUOI EDIFICI",
        "condominiale":"Condominiale",
        "privata":"Privata",
        "salvataggio_non_riuscito":"Salvataggio non riuscito",
        "informazioni_mancanti":"Informazioni mancanti",
        "avvia_analisi":"Avvia analisi",
        "data_di_scadenza":"Data di scadenza",
        "error_obtaining_communities_list":"Errore nell'ottenimento della lista delle community",
        "ricerca_x_nome":"Ricerca per nome",
        "error_creating_survey":"Errore nella creazione del sondaggio",
        "indietro":"Indietro",
        "in_attesa_delle_risposte_condomini":"In attesa delle risposte dei condòmini...",
        "link_raccolta_dati":"Link raccolta dati",
        "termina_ora":"Termina ora",
        "risultati":"Risultati",
        "risposte_totali_ottenute":"Risposte totali ottenute",
        "edifici_coinvolti":"Edifici coinvolti",
        "risposte":"Risposte",
        "surveyStartTitle":"Compila il sondaggio",
        "surveyStopTitle":"Il sondaggio è terminato",
        "not_found":"Non trovato",
        "community_non_trovata":"Community non trovata.",
        "edificio_gia_in_altre_comunita": "Edificio già compreso in altra comunità",
        "edificio_gia_in_altre_comunita_ipotesi":"Edificio già compreso in altra ipotesi di comunità",
        "distanza_eccessiva":"Distanza eccessiva",
        "completa_il_sondaggio":"Completa il sondaggio",
        "edificio_selezionato":"Edificio selezionato",
        "other":"Altro",
        "orario":"Orario",
        "spezzato":"Spezzato",
        "ora_inizio":"Ora inizio",
        "ora_fine":"Ora fine",
        "aperto24h":"Aperto 24h",
        "chiuso":"Chiuso",
        "orario_inizio_fine":"Orario inizio - Ora fine",
        "pagamenti":"Pagamenti",
        "gestione_comunita":"Gestione comunità",
        "comunita_in_sviluppo":"Comunità in sviluppo",
        "inserisci_nome_comunita":"Inserisci nome comunità",
        "ultima_modifica":"Ultima modifica",
        "stato":"Stato",
        "sconosciuto":"Sconosciuto",
        "attendi_sondaggio":"Attendi sondaggio",
        "inizia_analisi":"Inizia analisi",
        "scegli_edifici":"Scegli edifici",
        "delibera_assemblea":"Delibera in assemblea",
        "edifici_catasto_energetico":"Gli edifici di catasto energetico",
        "comunita_energo":"Tutte le comunità",
        "in_sviluppo":"In sviluppo",
        "la_data_non_puo_essere_antecedente":"La data non può essere antecedente",
        "salvataggio_riuscito":"Salvataggio riuscito",
        "esamina_risultati_e_procedi":"Esamina i risultati e procedi",
        "riepilogo":"Riepilogo",
        "comunita_energetica":"Comunità energetica",
        "impianti_fotovoltaici":"Impianti fotovoltaici",
        "compensi_sviluppatore":"Compensi sviluppatore",
        "dettaglio":"Dettaglio",
        "aggiungi_edifici":"Aggiungi edifici",
        "successivo":"Successivo",
        "problemi_onboarding_owner":"Problemi con la registrazione dell'amministratore",
        "required_valid_website":"Inserisci un indirizzo web valido",
        "no_community":"Non ci sono comunità",
        "title_onboarding":"Abbiamo integrato nuove funzionalità",
        "subtitle_onboarding":"D'ora in avanti potrai gestire i pagamenti direttamente in app e accedere a nuove funzionalità come lo sviluppo di comunità energetiche. Sono però necessari alcune informazioni aggiuntive",
        "per_modificare_torna_catasto":"Per modificare questi campi torna a Modifica dati personali dalla home",
        "problemi_nel_caricamento":"Problemi nel caricamento",
        "ditta":"Ditta",
        "nominativo":"Nominativo",
        "gestione_sottoscrizioni":"Abbonamento edifici",
        "dati_edificio":"Dati edificio",
        "verifica_lo_stato_degli_abbonamenti_a_catasto":"Verifica lo stato degli abbonamenti a Catasto Energetico",
        "verifica_stato_edifici_amministrati_contractor":"Verifica lo stato degli abbonamenti a Catasto Energetico di ciascun edificio di cui sei Terzo responsabile/Manutentore",
        "subtitle_onboarding_building":"Rivedi le informazioni associate all'edificio ed inserisci l'IBAN del condominio sul quale verrà addebitato l'abbonamento a Catasto Energetico",
        "inserisci_iban_condominio":"Inserisci iban condominio",
        "sottoscrizione_effettuata_con_successo":"Sottoscrizione effettuata con successo",
        "sviluppo_gestione_comunita":"Sviluppo gestione comunità",
        "iban_condominio":"Iban condominio",
        "modifica_iban":"Modifica iban",
        "message_cc_disabled":"Verifica di aver inserito una carta di credito valida.",
        "metodo_di_pagamento_aggiornato":"Metodo di pagamento aggiornato.",
        "i_tuoi_dati_di_pagamento":"Dati di pagamento",
        "dati":"Dati",
        "subtitle_i_tuoi_dati_di_pagamento":"In questa sezione puoi inserire il tuo IBAN personale. Sarà utilizzato solo per accrediti futuri nei tuoi confronti",
        "subtitle_i_tuoi_dati_di_pagamento_manutentore":"In questa sezione è possibile modificare i tuoi dati di pagamento",
        "dati_di_pagamento":"Dati di pagamento",
        "modifica_dati_carta_di_credito":"Modifica dati carta di credito",
        "last4":"Ultimi 4 numeri della carta",
        "le_comunita_in_sviluppo":"Le comunità in sviluppo",
        "da_inserire_dati_coperture":"Da inserire dati coperture",
        "gestisci":"Gestisci",
        "le_comunita_in_gestione":"Le comunità in gestione",
        "in_gestione":"In gestione",
        "compila_dati":"Compila dati",
        "inserire_dati_coperture":"Inserire dati coperture",
        "link_sondaggio":"Link sondaggio",
        "scandeza_link_sondaggio":"Scandeza link sondaggio",
        "data_creazione":"Data di creazione",
        "email":"Email",
        "amministratore":"Amministratore",
        "telefono":"Telefono",
        "torna_a_gestione_comunita":"Torna a gestione comunità",
        "edifici_inseriti":"Edifici inseriti",
        "note":"Note",
        "aree_individuate":"Aree individuate",
        "aggiungi_area":"Aggiungi area",
        "area":"Area",
        "lunghezza":"Lunghezza",
        "larghezza":"Larghezza",
        "azimut":"Azimut",
        "benvenuto":"Benvenuto",
        "comunita_energetiche":"Comunità energetiche",
        "delibera_in_assemblea_carica_doc":"Delibera in assemblea e carica i documenti richiesti",
        "condomini_coperture_interessate":"Condomini le cui coperture sono interessate",
        "nuovi_impianti":"Nuovi impianti",
        "numero_impianti":"Numero impianti",
        "investimento_stimato":"Investimento stimato",
        "scarica_materiale_informativo_assemblea":"Scarica materiale informativo da portare in assemblea",
        "scarica_incarico_progettazione_esecutiva":"Scarica incarico progettazione esecutiva",
        "carica_incarico_progettazione_esecutiva_firmato":"Carica incarico progettazione esecutiva firmato",
        "carica_delibera_incarico":"Carica delibera incarico",
        "scegli_file":"Scegli file",
        "procedi_con_la_costituzione":"Procedi con la costituzione",
        "documenti_da_scaricare":"Documenti da scaricare",
        "carica_i_seguenti_documenti_firmati":"Carica i seguenti documenti firmati",
        "carica_documenti":"Carica documenti",
        "carica_statuto_firmato":"Carica statuto firmato",
        "scarica_statuto_comunita":"Scarica Statuto comunità",
        "residenziale":"Residenziale",
        "ufficio":"Ufficio",
        "altre_attivita":"Negozio/Artigiano/Ristorante",
        "dati_identificativi":"Dati identificativi",
        "saving_survey_ok":"Sondaggio inviato con successo",
        "error_saving_survey":"Errore nel salvataggio del sondaggio",
        "sondaggio_non_trovato":"Sondaggio non trovato o scaduto",
        "sondaggio_completato":"Hai finito! Grazie per aver compilato il sondaggio.",
        "amministratore_manutentore_nome":"Nome dell'amminstratore o manutentore",
        "not_available":"Non disponibile",
        "risultati_non_ancora_disponibili":"Risultati non ancora disponibili",
        "non_ci_sono_edifici":"Non ci sono edifici",
        "seleziona_tutti":"Seleziona tutti",
        "edifici_considerati":"Edifici considerati",
        "importo_iva_22inclusa":"Importo (iva 22% inclusa):",
        "edificio": "Edificio",
        "riepilogo_edifici_ddot":"Riepilogo edifici:",
        "riepilogo_edifici":"Riepilogo edifici",
        "pagamento_e_durata":"Pagamento e durata",
        "carta_addebito_ddot":"Carta di addebito:",
        "durata_12_mesi_ddot":"Durata 12 mesi:",
        "iban_addebito_ddot":"IBAN addebito: ",
        "periodo_addebito_12_mesi":"Periodo di addebito 12 mesi:",
        "validita_12_mesi":"Validità 12 mesi:",
        "modifica_coperture":"Modifica coperture",
        "elimina":"Elimina",
        "rigenera_risultati":"Rigenera risultati",
        "e_stata_avviata_la_generazione_dei_risultati":"E' stata avviata con successo la rigenerazione dei risultati",
        "problemi_avvio_rigenerazione":"Problemi nella richiesta di rigenerazione dei risultati",
        "error_cancel_community":"Errore nella cancellazione della comunità",
        "community_cancellata_con_successo":"Community cancellata con successo",
        "sei_sicuro_di_voler_cancellare_la_comunita":"Sei sicuro di voler cancella la comunità: ",
        "sei_sicuro_di_voler_sospendere_abbonamento_edificio":"Sei sicuro di voler sospendere l'abbonamento per l'edificio:",
        "community_aggiornata":"Comunità aggiornata con successo",
        "error_update_community":"Errore aggiornamento comunità",
        "scarica_json_risultati":"Scarica json risultati",
        "nome":"Nome",
        "stato_attuale":"Stato attuale",
        "privacy":"Privacy",
        "coperture":"Coperture",
        "sondaggio":"Sondaggio",
        "turno_unico":"Turno unico",
        "onboarding_problem_creation_user":"Problemi nella creazione dell'utente, contatta il supporto",
        "payment_method_impostato":"Metodo di pagamento non impostato, non puoi procedere",
        "inserisci_la_carta_di_credito":"Inserisci la carta di credito",
        "pagamento_non_riuscito_contatta_assistenza":"Pagamento non riuscito: riprova nei prossimi giorni o contatta l'assistenza tecnica: backoffice@catastoenergetico.org",
        "error_aggiornamento_metodo_di_pagamento":"Problemi nell'aggiornamento del pagamento, riprova o contatta l'assistenza tecnica: backoffice@catastoenergetico.org",
        "inserisci_identificativo_fatture":"Inserisci il codice identificativo per l'invio delle fatture(SDI o pec)",
        "identificativo_fatture":"Identificativo delle fatture(SDI o PEC)",
        "modifica_dati":"Modifica dati",
        "inserisci_dati_cc_contractor":"Inserisci i dati della carta di credito/debito con cui potrai effettuare pagamenti per gli edifici",
        "dati_profilo":"Profilo",
        "i_tuoi_dati_profilo":"I tuoi dati del profilo",
        "modifica":"Modifica",
        "error_updating_profile":"Errore nell'aggiornamento del profilo",
        "problemi_metodo_di_pagamento_edificio":"Il tuo edificio risulta già registrato, ma non riusciamo a recuperare il metodo di pagamento precedentemente impostato, contatta l'assistenza tecnica: backoffice@catastoenergetico.org",
        "inserimento_dati_coperture":"Inserimento dati coperture",
        "non_risultano_edifici_coperture":"Non risultano edifici che necessitano di inserimento delle coperture, verifica che siano stati compilati i dati",
        "in_attesa_di_conferma":"In attesa di conferma",
        "offerta_da":"Offerto da:",
        "indirizzo_edificio":"Indirizzo edificio:",
        "emailSoggettoTerzo":"Email del soggetto terzo",
        "nomeSoggettoTerzo":"Nome del soggetto terzo",
        "dati_importazione_massiva":"Dati per importazione massiva edifici/amministratori",
        "dati_importazione_massiva_subtitle":"In questa sezione potrai caricare un file csv che importerà in automatico gli edifici e gli amministratori, associandoli se necessario",
        "requiredNomeSoggettoTerzo":"Il nome del soggetto terzo è obbligatorio",
        "requiredEmailSoggettoTerzo":"La mail del soggetto terzo è obbligatoria",
        "required_csv":"Il csv è obbligatorio, verifica di averlo caricato correttamente o riprova",
        "importazione_completata":"Importazione completata.",
        "tipo_di_importazione":"Tipo di importazione",
        "simple":"Semplificata",
        "terzo":"Terzo",
        "scarica_qrcode":"Scarica Qr-Code",
        "copyright_footer":"© 2011-2024 Catasto Energetico. All Rights Reserved.",
        "vedi_risposte":"Vedi chi ha risposto",
        "definisci_configurazione":"Definisci configurazione",
        "procedi_configurazione":"Procedi configurazione",
        "configurazione":"Configurazione",
        "scegli_edifici_compresi_configuraz":"Scegli edifici compresi nella configurazione",
        "scegli_la_configurazione":"Scegli la configurazione",
        "scegli_configurazione_intro_pt1":"A valle della decisione delle assemblee, ",
        "scegli_configurazione_intro_pt2":" definitiva. Gli ",
        "edifici_preselezionati":"edifici preselezionati",
        "scegli_configurazione_intro_pt3":" sono quelli consigliati da Energo AI.",
        "configurazione_selezionata":"Configurazione selezionata",
        "avendo_selezionato_piu_edifici_conf_disp":"Avendo selezionato più di un edificio l'unica configurazione possibile è:",
        "btn_conf_cer":"COMUNITA\nENERGETICA\nRINNOVABILE (CER)",
        "btn_conf_auc":"GRUPPO DI\nAUTOCONSUMATORI\n(AUC)",
        "avendo_selezionato_pt1":"Avendo selezionato un solo edificio puoi decidere se procedere con le pratiche per un ",
        "avendo_selezionato_pt2":" o una ",
        "vantaggi":"VANTAGGI",
        "svantaggi":"SVANTAGGI",
        "delibera_assemblea_e_carica_documenti_richiesta":"Delibera in assemblea e carica i documenti richiesti",
        "soggetto_giuridico_pt1":"Come primo passo è necessario procedere con la costituzione del nuovo soggetto giuridico.",
        "scarica_i_template":" Scarica i template,",
        "soggetto_giuridico_pt2":"costituisci la comunità o l'autoconsumatore collettivo.(fai richiesta di codice fiscale presso l'Agenzia delle Entrate nel caso di Comunità energetica) e ",
        "soggetto_giuridico_pt3":"carica i documenti indicati qui sotto firmati.",
        "da_completare":"Da completare",
        "soggetto_giuridico":"Soggetto giuridico",
        "documenti_per_la_costituzione_di_un_nuovo_soggetto_giuridico":"Documenti per la costituzione del nuovo soggetto giuridico",
        "impianto_pt1":"Per tutti i nuovi impianti fotovoltaici è necessario raccogliere alcuni",
        "documenti":" documenti ",
        "impianto_pt2":"che vengono prodotti dal ",
        "progettista":"Progettista",
        "impianto_pt3":" e dalla ",
        "ditta_installatrice":"installatrice.",
        "impianto_pt4":" Inserisci nel seguito nome e recapito email del professionista e dell'installatore al fine di ricordargli di caricare i documenti. Catasto Energetico invierà per te la richiesta di tutti i documenti necessari. Nel pannello a destra puoi monitorare l'andamento del caricamento dei dati.",
        "membri_pt1":"Infine condividi il seguente link a tutti i ",
        "tutti":"tutti",
        "membri":"membri",
        "membri_pt2":" della configurazione per raccogliere i dati necessari da ogni membro. Nella casella a destra puoi monitorare l'andamento della raccolta.",
        "membri_della_configurazione":"Membri della configurazione",
        "documenti_dell_impianto":"Documenti dell'impianto di ",
        "incentivi_pt1":"Non appena tutti i ",
        "incentivi_pt2": " saranno caricati, Catasto Energetico inoltrerà la richiesta al GSE per l'ottenimento degli",
        "incentivi":" incentivi",
        "scarica_i_template_compilali_e_ricaricali":"Scarica i template,\ncompilali e ricaricali",
        "scarica":"Scarica",
        "regolamento":"Regolamento",
        "statuto":"Statuto",
        "atto_costitutivo":"Atto costitutivo",
        "procura":"Procura",
        "carica_file":"Carica file",
        "file_supportati":"File supportati",
        "atto_costitutivo_delibera":"Atto costitutivo / delibera",
        "dati_indicati_pt1":"Dopo aver costituito la comunità, ",
        "inserisci_i_dati_indicati":"inserisci i dati indicati ",
        "dati_indicati_pt2":"qui sotto in modo da permetterci di presentare la richiesta di accesso al servizio di incentivazione dell'energia.",
        "intestatario_cc": "Intestatario c/c",
        "codice_fiscale":"Codice fiscale",
        "nome_soggetto_giuridico":"Nome soggetto giuridico",
        "invia_richiesta":"Invia richiesta",
        "invia_richiesta_pt1":" al progettista e all'installatore di caricare i documenti necessari per le prossime fasi.",
        "documenti_caricati":"Documenti caricati",
        "progettazione_esecutiva":"Progettazione esecutiva",
        "nome_progettista":"Nome progettista",
        "indirizzo_email":"Indirizzo e-mail",
        "installazione_impianto":"Installazione impianto",
        "nome_azienda":"Nome azienda",
        "schema_elettrico_unifilare":"Schema elettrico unifilare",
        "verbale_attivazione_contatore":"Verbale di attivazione del contatore",
        "regolamento_di_esercizio":"Regolamento di esercizio",
        "attestato_esercizio_gaudi":"Attestato di esercizio Gaudi",
        "checklist_dnsh":"Checklist DNSH",
        "dati_economici_impianto":"Dati economici impianto",
        "foto_etichette_impianto_inverter":"Foto delle etichette dell'impianto, inverter accumulo",
        "numero_matricola_pannelli":"Numero di matricola dei pannelli installati",
        "l_impianto_di":"L'impianto di",
        "sara_realizzato_dalle_stesse_aziende":"sarà realizzato dalle stesse aziende che realizzeranno l'impianto",
        "produttore_pt1":"Il ",
        "produttore":"Produttore",
        "produttore_pt2":" è l'intestatario dell'officina elettrica di produzione dell'energia ovvero il firmatario del regolamento di esercizio dell'impianto. ",
        "nel_caso_in_cui_produttore_non_coincida_cer_auc":"Nel caso in cui il produttore non coincida con il referente della CER o dell'AUC",
        "produttore_pt3":",è necessario compilare e firmare un mandato e caricarlo corredato di documento di identità. Nel caso in cui il produttore degli impianti coincida con il referente, non è necessario fornire mandato.",
        "documento_identita":"Documenti d'identità",
        "mandato_firmato":"Mandato firmato",
        "produttori":"Produttori",
        "clicca_sul_link":"Clicca sul link",
        "membri_conf_link_pt1":" sottostante o",
        "inquadra_qr_code":" inquadra il Qr-Code ",
        "membri_conf_link_pt2":" per compilare i dati riguardanti i POD condominiali. ",
        "invia":"Invia",
        "membri_conf_link_pt3":" poi lo stesso link o il Qr-Code ai membri della configurazione, con un copia e incolla o scaricando e inviando il QR code, per permettere il caricamento dei dati.",
        "risposte_ottenute":"Risposte ottenute",
        "dati_catastali":"Dati catastali",
        "conferma_dati_catastali":"Conferma i dati catastali dell'immobile in cui è stato realizzato l'impianto",
        "subAlterno":"Subalterno",
        "solo_se_presente":"* solo se presente",
        "dati_posizionali":"Dati posizionali",
        "conferma_i_dati_posizionali":"Conferma i dati dell'immobile in cui è stato realizzato l'impianto",
        "inclinazione_rispetto_azimut":"Inclinazione rispetto all'azimut:",
        "esposizione_prevalente":"Esposizione prevalente:",
        "longitudine_wgs84":"Longitudine (WGS84 decimali):",
        "latitudine_wgs84":"Latitudine (WGS84 decimali):",
        "conferma_dati_posizionali":"Conferma i dati dell'immobile in cui è stato realizzato l'impianto",
        "impianto_dotato_di_inseguimento_solare":"L'impianto è dotato di dispositivo di inseguimento solare?",
        "impianto_costituito_pannelli_bifacciali":"L'impianto è costituito da pannelli bifacciali?",
        "carica_documenti_dell_impianto_di":"Carica i documenti relativi all'impianto di",
        "file_caricato":"File caricato",
        "moduli_fotovoltaici":"Moduli fotovoltaici",
        "inverter":"Inverter",
        "tecnologia":"Tecnologia",
        "marca":"Marca",
        "modello":"Modello",
        "potenza":"Potenza",
        "numero_moduli":"Numero moduli",
        "matricola":"Matricola",
        "foto_etichetta_ogni_modulo_fotovoltaico":"FOTO DELL'ETICHETTA DI OGNI MODULO FOTOVOLTAICO",
        "foto_targhetta_ogni_inverter":"FOTO DELLA TARGHETTA DI OGNI INVERTER",
        "numero_matricola_pannelli_installati":"NUMERO DI MATRICOLA DEI PANNELLI INSTALLATI",
        "seleziona_il_tuo_condominio":"Seleziona il tuo condominio",
        "inserisci_i_dati_intestatario_pt1":"Inserisci i dati dell' ",
        "intestatario":"intestatario",
        "inserisci_i_dati_intestatario_pt2":" della bolletta elettrica",
        "dati_giuridici":"Dati giuridici",
        "persona_fisica":"Persona fisica",
        "imprenditore_ditta_individuale_impresa_agricola":"Imprenditore, ditta individuale, impresa agricola",
        "persona_giuridica":"Persona giuridica",
        "condominio":"Condominio",
        "piva":"P.IVA",
        "nome_cognome":"Nome e Cognome",
        "interno":"Interno",
        "localita_di_residenza":"Località di residenza",
        "altri_dati":"Altri dati",
        "subalterno":"Subalterno",
        "sez_urbana":"Sez. Urbana",
        "codice_ateco":"Codice ateco",
        "codice_pod":"Codice Pod",
        "nazione":"Nazione",
        "comune":"Comune",
        "sede_legale":"Sede legale",
        "cap_sigla":"C.A.P",
        "civico":"Civico",
        "indirizzo":"Indirizzo",
        "rapp_legale":"Rappresentante legale",
        "procuratore":"Procuratore",
        "firmatario":"Firmatario",
        "ragioneSociale":"Ragione Sociale",
        "pod":"POD",
        "inserisci_codice_pod_pt1":"Inserisci il codice ",
        "inserisci_codice_pod_pt2":" lo trovi in\n bolletta(prima pagina)",
        "documenti_dei_produttore_impianti":"Documenti dei produttori degli impianti",
        "scarica_mandato":"Scarica mandato",
        "mandato":"Mandato",
        "impianti":"Impianti",
        "elimina_file":"Elimina file",
        "carica_documento":"Carica documento",
        "policristallino":"Policristallino",
        "monocristallino":"Monocristallino",
        "filmSottile":"Film sottile",
        "amorfo":"Amorfo",
        "informazioni_inserite":"Informazioni inserite con successo",
        "error_saving_member":"Errore nel salvataggio dei dati del membro della comunità",
        "file_rimosso_con_successo":"File rimosso con successo.",
        "error_rimozione_successo":"Errore nella rimozione del file.",
        "caricamento_completato":"Caricamento completato",
        "errore_caricamento_files":"Errore caricamento files",
        "errore_creazione_configurazione":"Errore nella creazione della confiurazione della comunità",
        "configurazione_creata_con_successo":"Configurazione creata con successo",
        "documentazione_aggiornata":"Documentazione aggiornata",
        "documentazione_non_aggiornata":"Documentazione non aggiornata",
        "nessun_impianto_selezionato":"Non hai selezionato nessun impianto, non puoi procedere con la configurazione.",
        "invia_richieste":"Invia richieste",
        "richieste_inviata_con_successo":"Richieste inviate con successo",
        "errore_invio_richieste":"Problemi nell'invio delle richieste.",
        "delibera_in_assemblea":"Delibera in assemblea",
        "errore_elaborazione":"Errore di elaborazione",
        "apri":"Apri",
        "errore_download":"Problemi nel download del file.",
        "recapiti":"Recapiti",
        "completato":"Completato",
        "copia_link":"Copia link",
        "scarica_compilazioni_membri":"Scarica JSON compilazioni membri",
        "richiesta_inviata_con_successo":"Richiesta inviata con successo",
        "errore_invio_richiesta":"Problemi nell'invio della richiesta.",
        "riapri_sondaggio":"Riapri sondaggio",
        "quale_numero_appartamenti_interni":"Qual è il numero indicativo di interni del condominio (abitazioni, uffici, attività…)?",
        "partecipazione_parziale":"Partecipazione parziale",
        "partecipazione_totale":"Partecipazione totale",
        "dettaglio_investimento":"Dettaglio investimento",
        "dettaglio_per_edificio":"Dettaglio per edificio",
        "consumatore":"Consumatore",
        "edifici":"Edifici",
        "nessun_impianto":"Nessun impianto",
        "potenza_impianto":"potenza impianto",
        "edificio_escluso_conf":"Edificio escluso dalla configurazione",
        "inserisci_i_dati":"Inserisci i dati",
        "documenti_caricati_dai_professionisti":"Documenti caricati dai professionisti",
        "torna_alla_scelta_degli_edifici":"Torna alla scelta degli edifici",
        "attenzione":"Attenzione",
        "warning_modifica_edifici_perdi_risultati":"Se modifichi la configurazione della comunità, perderai i risultati ottenuti. Tutti i dati inseriti associati agli edifici e i questionari ottenuti verranno mantenuti e utilizzati per calcolare i risultati della nuova configurazione.",
        "error_comunita_non_sviluppabile":"Non è stato possibile generare i risultati poichè in nessuno degli edifici considerati è possibile installare un impianto fotovoltaico.",
        "elaborazione_risultati":"Elaborazione dei risultati",
        "crea_comunita":"Crea comunità",
        "nome_comunita_title":"Nome Comunità",
        "link_copiato":"Link copiato con successo",
        "elaborazione_completata":"Elaborazione completata",
        "richiedi_contatto":"Richiedi contatto",
        "clicca_su":"Clicca su ",
        "richiedi_contatto_min":"richiedi contatto",
        "per_ricevere_una_nostra_telefonata_assieme_risultati":" per ricevere una nostra telefonata. Esamineremo assieme i risultati ottenuti e ti forniremo tutte le indicazioni per procedere con la costituzione della tua configurazione di autoconsumo diffuso.",
        "approva_richiesta_dopo_contatto":"Approva richiesta dopo contatto",
        "approvazione_richiesta_dopo_contatto_successo":"Approvazione andata a buon fine!",
        "errore_approvazione_richiesta_dopo_contatto":"Errore approvazione richiesta dopo contatto!",
        "autoconsumatore_collettivo":"Autoconsumatore collettivo",
        "sconto_disclaimer_building":"Usufruisci di un prezzo scontato del ",
        "rigenera_coordinate_identificativo":"Rigenera coordinate ed identificativo",
        "operazione_failed":"Operazione fallita",
        "operazione_success":"Operazione eseguita con successo",
        "rigenera_coordinate_gse_identificativo":"Rigenera per tutti gli edifici GSE identifier e coordinate",
        "gli_edifici_selezionati":"Gli edifici selezionati",
        "gli_edifici_che_amministri":"Gli edifici che amministri"
    }
  }
};
i18n
  .use(initReactI18next)
  .init({
    resources,
    lng: 'it',
    fallbackLng: 'it',
    interpolation: {
      escapeValue: false
    }
  });
